$success: #14ac5b;
$dark: #141823;

$navbar-padding-y: 1rem;
$navbar-padding-x: 1rem;
$navbar-dark-color: #fff;

$enable-rounded: true;

.navbar-brand {
  font-weight: bold;
}

.navbar-nav {
  font-weight: bold;
}

@import "~bootstrap/scss/bootstrap";

body {
  padding-top: 7rem;
  padding-bottom: 5.5rem;
}

.send {
  width: 100%;
  margin: 0 auto;

  .connection {
    padding: 1rem 0 1rem 3rem;
    margin-bottom: 1rem;

    position: relative;
    color: $dark;
    border: 1px solid $gray-300;

    .statusText {
      color: $gray-600;
    }

    .statusIcon {
      display: block;
      position: absolute;
      top: 1.25rem;
      left: 1rem;
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;

      &.success {
        background-color: $success;
      }
    }
  }
}

@media (min-width: 1200px) {
  .send {
    width: 60%;
  }
}
